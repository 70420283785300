import React, { useRef, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '@reach/router';
import Img from 'gatsby-image';
import NavbarLink from './NavbarLink';

export default function NavbarDrawer() {
  if (typeof window === `undefined`) {
    return <div />;
  }

  const sidenavRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      const isDrawerOpen = document.getElementById('sidenav').style.width === '70%';

      if (sidenavRef.current && isDrawerOpen && !sidenavRef.current.contains(event.target)) {
        closeNav();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidenavRef]);

  function openNav() {
    document.getElementById('sidenav').style.width = '70%';
    document.getElementById('backdrop').style.display = 'block';
    document.getElementById('hamburger').style.display = 'none';
  }

  function closeNav() {
    document.getElementById('sidenav').style.width = '0';
    document.getElementById('backdrop').style.display = 'none';
    document.getElementById('hamburger').style.display = 'block';
  }

  function getLinkType() {
    const isHomePage = location.pathname === '/';

    if (isHomePage) {
      return 'scroll';
    }

    return 'transition';
  }

  const logo = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Ultrasano_Logo_White.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <nav ref={sidenavRef} id="sidenav" className="sidenav-container">
        <div className="close-button-container">
          <span onClick={closeNav} className="close-button">
            <i className="fa fa-plus"></i>
          </span>
        </div>
        <ul>
          <li className="line">
            <NavbarLink
              to="/"
              scrollTo="hero"
              scrollLinkTo="hero"
              linkText="Početna"
              onClick={closeNav}
              type={getLinkType()}
            />
          </li>
          <li className="line">
            <NavbarLink
              to="/"
              scrollTo="about"
              scrollLinkTo="about"
              linkText="O nama"
              onClick={closeNav}
              type={getLinkType()}
            />
          </li>
          <li className="line">
            <NavbarLink
              to="/"
              scrollTo="services"
              scrollLinkTo="services"
              linkText="Usluge"
              onClick={closeNav}
              type={getLinkType()}
            />
          </li>
          <li className="line">
            <NavbarLink to="/cjenik" linkText="Cjenik" type="transition" />
          </li>
        </ul>
      </nav>

      <div className="backdrop-container" id="backdrop"></div>
      <nav id="navbar">
        <div className="navbar-container">
          <Link to="/">
            <Img className="white-logo" fluid={logo.file.childImageSharp.fluid} alt="" />
          </Link>
          <span id="hamburger" onClick={openNav} className="hamburger">
            <i className="fa fa-bars" />
          </span>
        </div>
      </nav>
    </React.Fragment>
  );
}
