import React, { useCallback, useEffect, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import NavbarDrawer from './NavbarDrawer';
import NavbarNormal from './NavbarNormal';

export default function Navbar() {
  const { width } = useWindowDimensions();
  const [navClass, setNavClass] = useState('');

  const getWindowHeight = useCallback(() => {
    const distanceY =
      (typeof window !== `undefined` && window.pageYOffset) || document.documentElement.scrollTop;

    if (distanceY > 0) {
      setNavClass((value) => {
        if (value === 'smaller') {
          return value;
        }

        return 'smaller';
      });
    } else {
      setNavClass('');
    }
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', getWindowHeight);
    }
    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener('scroll', getWindowHeight);
      }
    };
  }, [getWindowHeight]);

  const isMobile = width < 754;

  if (isMobile) {
    return <NavbarDrawer className={navClass} />;
  }

  return <NavbarNormal className={navClass} />;
}
