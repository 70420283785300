import React from 'react';
import { useLocation } from '@reach/router';
import NavbarLink from './NavbarLink';

export default function NavbarNormal({ className }) {
  if (typeof window === `undefined`) {
    return <div />;
  }

  const location = useLocation();

  function getLinkType() {
    const isHomePage = location.pathname === '/';

    if (isHomePage) {
      return 'scroll';
    }

    return 'transition';
  }

  return (
    <nav id="navbar" className={className}>
      <ul>
        <li className="line">
          <NavbarLink
            to="/"
            scrollTo="hero"
            scrollLinkTo="hero"
            linkText="Početna"
            type={getLinkType()}
          />
        </li>
        <li className="line">
          <NavbarLink
            to="/"
            scrollTo="about"
            scrollLinkTo="about"
            linkText="O nama"
            type={getLinkType()}
          />
        </li>
        <li className="line">
          <NavbarLink
            to="/"
            scrollTo="services"
            scrollLinkTo="services"
            linkText="Usluge"
            type={getLinkType()}
          />
        </li>
        <li className="line">
          <NavbarLink to="/cjenik" linkText="Cjenik" type="transition" />
        </li>
      </ul>
    </nav>
  );
}
