import React from 'react';
import { Link } from '@reach/router';
import { Link as ScrollLink } from 'react-scroll';
import AnimatedTransition from '../AnimatedTransition/AnimatedTransition';

export default function NavbarLink({
  to,
  linkText = 'Link text',
  scrollLinkTo,
  scrollTo,
  onClick = () => {},
  type = 'link',
}) {
  if (type === 'scroll') {
    return (
      <ScrollLink to={scrollLinkTo} smooth duration={1000} offset={-100} onClick={onClick}>
        {linkText}
      </ScrollLink>
    );
  }

  if (type === 'transition') {
    return <AnimatedTransition to={to} linkText={linkText} />;
  }

  return (
    <Link to={to} state={{ scrollTo }} onClick={onClick}>
      {linkText}
    </Link>
  );
}
