import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function AnimatedTransition({ to, linkText }) {
  if (typeof window === `undefined`) {
    return <div />;
  }

  const location = useLocation();
  const { width } = useWindowDimensions();

  const aniLinkProps = {
    cover: true,
    direction: 'left',
    duration: 1.5,
    bg: `
          url(ultrasano-transition-logo.png)
          ${width < 550 ? 'center / 250px 130px' : 'center / 400px 220px'}  /* position / size */
          no-repeat        /* repeat */
          fixed            /* attachment */
          padding-box      /* origin */
          content-box      /* clip */
          #303e82            /* color */
        `,
    to,
  };

  useEffect(() => {
    if (location.pathname === to) {
      document.getElementById(`animation-link-${linkText}`).style.pointerEvents = 'none';
    }

    return () => {};
  }, [location]);

  return (
    <AniLink id={`animation-link-${linkText}`} {...aniLinkProps}>
      {linkText}
    </AniLink>
  );
}
